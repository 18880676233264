import config from '@/config';
import { UserTestimonial } from './Testimonial';

// The list of your testimonials. It needs 11 items to fill the grid. The last one (11th) is featured on large devices (span 2 columns + big font)
export const list: UserTestimonial[] = [
  {
    // Show @username for social media like Twitter. Does not link anywhere but cool to display
    username: 'leadingin5th',
    name: 'Heather Reynolds',
    text: 'Using @QuizWhizzer to review before our post test today! This is a new to us tool and they’re loving it! @southnccs',
    // use refTypes.other if you don't want to display an icon
    platform: 'twitter',
    // Link to the person's testimonial. It's more trustable
    link: 'https://twitter.com/leadingin5th/status/1232664082748203009',
    // A statically imported image (usually from your public folder—recommended) or a link to the person's avatar. Shows a fallback letter if not provided
    img: '/testimonial-avatars/heather.jpg',
  },
  {
    name: 'Emily',
    text: 'Very very impressed. I teach at a vocational beauty college and I utilize this tool all the time. My adult learning students are so impressed and love how user friendly it is!',
  },
  {
    name: 'Martine Kruss',
    text: 'What an amazing platform for teachers. It allows students to have fun while practicing vocabulary, listening skills and many other assessment standards. My student ask for more homework!',
  },
  {
    name: 'Chelsey Culley Love',
    text: 'This was awesome! My students loved it and played it over and over!',
  },
  {
    name: 'Tutor',
    text: `As a teacher with huge experience I can say that QuizWhizzer is a must-have tool to make the education process more simple and fun. This service is 100% adjustable which is very pleasant, you can set up any theme and connect it to the nearest holidays, for example, or to use the different types of questions, etc.
Students stress out way less during the tests which are made with QuizWhizzer and actually they do not percieve it as a test. Generally they form the more positive attitude to the process of education and studying subject and learn the topic actively and deeper, knowing that in the end of the classes the adventure in QuizWhizzer will be awaiting.
Apart of that, one more feature of this service is the ability to share your tests and games with other users through the common library, which is very important, by my opinion.
So I would definitely recommend QuizWhizzer to teachers to gamify some part of studying process.`,
  },
  {
    username: 'SDziwanowski',
    name: 'Shannon Dziwanowski',
    text: 'Was excited to see my students race around the game board @QuizWhizzer as they reviewed Astronomy concepts! 🚀 Great tool for PRACTICE and ENGAGEMENT 🙌 Nothing like a little friendly competition to start the day @LarsonMS #gamifiction #learning #scienceisfun',
    platform: 'twitter',
    link: 'https://twitter.com/SDziwanowski/status/1334178922938953728',
    img: '/testimonial-avatars/shannon.jpg',
  },
  {
    name: 'Karen Jonovski',
    text: `I chose Quizwhizzer because it looked very engaging for students, there are a wide variety of question type options, the option to add so many graphics and mainly because it came with a free two week trial that is fully functional. AND... I didn't have to give my credit card before getting the free trial.
Great product. Thank you!
BONUS: Near immediate response from the creator when I ran into a small glitch -- it was managed within 24 hours. Awesome!`,
  },
  {
    name: 'Jen Charles',
    img: '/testimonial-avatars/Jen Charles.jpg',
    text: "I can't thank you enough for the site - this has been a great addition to my classes.",
  },
  {
    name: 'Amalina Johari',
    text: 'I must say, that I’m fall in love with this tool for gamification..simple, easy and very helpful to make learning into something fun and exciting..thank you very much for creating this awesome platform 🤩',
  },
  {
    name: 'Rocío Calderón García',
    text: 'Excellent platform, I recommend it for your students.',
  },
  // The last testimonial is featured on big devices (span 2 columns + big font) 👇
  {
    name: 'Graham Davison',
    text: 'After embedding QuizWhizzer games into most sessions through the years my A level results have continued to improve. The results of my Physics students have consistently ranked within the top 20% of the country via ALPS and as a result I would strongly recommend QuizWhizzer to any teacher.',
    platform: 'twitter',
    img: '/testimonial-avatars/Graham Davison.jpg',
  },
];
