'use client';

import React from 'react';
import config from '@/config';
import Image, { StaticImageData } from 'next/image';
import { cn } from '@/src/libs/utils';

type Props = {
  testimonial: UserTestimonial;
};

const platforms = {
  productHunt: {
    id: 'product_hunt',
    ariaLabel: 'See user review on Product Hunt',
    svg: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 26.245 26.256"
        className="w-[18px] h-[18px]"
      >
        <path
          d="M26.254 13.128c0 7.253-5.875 13.128-13.128 13.128S-.003 20.382-.003 13.128 5.872 0 13.125 0s13.128 5.875 13.128 13.128"
          fill="#da552f"
        />
        <path
          d="M14.876 13.128h-3.72V9.2h3.72c1.083 0 1.97.886 1.97 1.97s-.886 1.97-1.97 1.97m0-6.564H8.53v13.128h2.626v-3.938h3.72c2.538 0 4.595-2.057 4.595-4.595s-2.057-4.595-4.595-4.595"
          fill="#fff"
        />
      </svg>
    ),
  },
  twitter: {
    id: 'twitter',
    ariaLabel: 'See user post on Twitter',
    svg: (
      <svg
        className="w-5 h-5 fill-[#00aCee]"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
      >
        <path d="M19.633 7.997c.013.175.013.349.013.523 0 5.325-4.053 11.461-11.46 11.461-2.282 0-4.402-.661-6.186-1.809.324.037.636.05.973.05a8.07 8.07 0 0 0 5.001-1.721 4.036 4.036 0 0 1-3.767-2.793c.249.037.499.062.761.062.361 0 .724-.05 1.061-.137a4.027 4.027 0 0 1-3.23-3.953v-.05c.537.299 1.16.486 1.82.511a4.022 4.022 0 0 1-1.796-3.354c0-.748.199-1.434.548-2.032a11.457 11.457 0 0 0 8.306 4.215c-.062-.3-.1-.611-.1-.923a4.026 4.026 0 0 1 4.028-4.028c1.16 0 2.207.486 2.943 1.272a7.957 7.957 0 0 0 2.556-.973 4.02 4.02 0 0 1-1.771 2.22 8.073 8.073 0 0 0 2.319-.624 8.645 8.645 0 0 1-2.019 2.083z"></path>
      </svg>
    ),
  },
};

export type UserTestimonial = {
  username?: string;
  name: string;
  text: string;
  platform?: keyof typeof platforms;
  link?: string;
  img?: string | StaticImageData;
};

function truncateText(text: string, maxLength: number) {
  // If the text is shorter than the max length, return it as is.
  if (text.length <= maxLength) return text;

  // Find the last space before the maxLength
  let truncated = text.substring(0, maxLength);
  let lastSpaceIndex = truncated.lastIndexOf(' ');

  // If there's no space, or the space is too early in the text, slice at maxLength
  if (lastSpaceIndex === -1 || lastSpaceIndex < maxLength / 2) {
    truncated = text.substring(0, maxLength);
  } else {
    // Otherwise, slice at the last space
    truncated = text.substring(0, lastSpaceIndex);
  }

  return truncated + '...';
}

// A single testimonial, to be rendered in  a list
export default function Testimonial({ testimonial }: Props) {
  const maxChars = 400;
  const [isExpanded, setIsExpanded] = React.useState(false);
  const [isOverflow, setIsOverflow] = React.useState(false);
  const textRef = React.useRef<HTMLQuoteElement>(null);

  React.useEffect(() => {
    if (
      textRef.current?.scrollHeight &&
      textRef.current.scrollHeight > textRef.current?.clientHeight
    ) {
      console.log("IT'S BIGGER", testimonial.name);
      setIsOverflow(true);
    }
  }, []);

  if (!testimonial) return null;

  let platform = null;
  if (testimonial.platform) {
    platform = platforms[testimonial.platform];
  }

  let text = testimonial.text;

  if (!isExpanded) {
    text = truncateText(text, maxChars);
  }

  return (
    <li>
      <figure className="relative h-full p-6 bg-base-100 rounded-lg">
        <blockquote className="relative" ref={textRef}>
          <p className="text-sm text-base-content/80">{text}</p>
        </blockquote>
        {testimonial.text.length > maxChars && (
          <div className="mt-2">
            <button
              onClick={() => setIsExpanded((prev) => !prev)}
              className="text-base-content/70 hover:cursor-pointer"
            >
              {isExpanded ? 'Read less' : 'Read more'}
            </button>
          </div>
        )}
        <figcaption
          className={cn(
            'relative flex items-center justify-start gap-4 pt-4 border-t border-base-content/5',
            isOverflow ? 'mt-1' : 'mt-4'
          )}
        >
          <div className="overflow-hidden rounded-full bg-base-300 shrink-0">
            {testimonial.img ? (
              <Image
                className="w-10 h-10 rounded-full object-cover"
                src={testimonial.img}
                alt={`${testimonial.name}'s testimonial for ${config.appName}`}
                width={48}
                height={48}
              />
            ) : (
              <span className="w-10 h-10 rounded-full flex justify-center items-center text-lg font-medium bg-base-300">
                {testimonial.name.charAt(0)}
              </span>
            )}
          </div>
          <div className="w-full flex items-end justify-between gap-2">
            <div>
              <div className="text-sm font-medium text-base-content">
                {testimonial.name}
              </div>
              {testimonial.username && (
                <div className="mt-0.5 text-sm text-base-content/80">
                  @{testimonial.username}
                </div>
              )}
            </div>

            {testimonial.link && platform?.svg && (
              <a
                href={testimonial.link}
                target="_blank"
                className="shrink-0 "
                aria-label={platform.ariaLabel}
              >
                {platform.svg}
              </a>
            )}
          </div>
        </figcaption>
      </figure>
    </li>
  );
}
