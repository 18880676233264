'use client';

import React from 'react';

import config from '@/config';
import { cn } from '@/src/libs/utils';
import Image from 'next/image';
import styles from './FloatingPowerup.module.css';

type Props = {
  filename: string;
  className?: string;
  parallaxSpeed?: number;
};

export default function FloatingPowerup({
  filename,
  className,
  parallaxSpeed,
}: Props) {
  return (
    <div
      className={cn(
        'w-20 h-20 lg:w-[200px] lg:h-[200px]',
        className,
        styles.parallax
      )}
      style={{
        // @ts-expect-error
        '--parallax-speed': parallaxSpeed ?? 1,
      }}
    >
      <Image
        src={`${config.imagesBaseUrl}/powerups/${filename}`}
        fill
        alt="Powerup"
        className="object-contain w-full h-auto"
        sizes="(max-width: 1024px) 80px, 200px "
      />
    </div>
  );
}
